<template>
  <div class="pa-2 pa-md-6">
    <v-row>
      <v-col cols="12" class="pb-6">
        <file-input
          height="115px"
          :loading="uploadingImage"
          @change="uploadFileHandle"
        />
      </v-col>
    </v-row>
    <v-row v-if="uploadingImage">
      <v-col cols="12" class="py-6">
        <loading-files
          :file-name="uploadFileName"
          :value="fileUploadProgress"
          :cancel-function="source ? source.cancel : () => {}"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span class="text-subtitle-1">{{ $trans("your_pictures") }}:</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-6">
        <asset-list @loading="$emit('loading', $event)" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3 class="text-subtitle-1">{{ $trans("photo_library_unsplash") }}:</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-6">
        <unsplash-search-photos @loading="$emit('loading', $event)" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import uploadFileActions from "@/lib/calendesk-js-library/mixins/uploadFileActions";

export default {
  name: "AssetsDialog",
  mixins: [uploadFileActions],
};
</script>
