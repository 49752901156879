import { mapActions, mapMutations } from "vuex";
import axios from "axios";
import UnsplashSearchPhotos from "@/lib/calendesk-js-library/components/assets/UnsplashSearchPhotos";
import api from "@/lib/calendesk-js-library/api/api";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import { maxMbSize } from "@/lib/calendesk-js-library/forms/validators";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  components: {
    UnsplashSearchPhotos,
    FileInput: () =>
      import("@/lib/calendesk-js-library/components/assets/FileInput"),
    LoadingFiles: () =>
      import("@/lib/calendesk-js-library/components/assets/LoadingFiles"),
    AssetList: () =>
      import("@/lib/calendesk-js-library/components/assets/AssetList"),
  },
  data() {
    return {
      assetListLoading: false,
      unsplashLoading: false,
      uploadingImage: false,
      uploadFileName: "",
      source: null,
      fileUploadProgress: 0,
      maxSizeInMb: 10,
    };
  },
  methods: {
    ...mapMutations({
      setReload: "assets/SET_RELOAD",
      setMode: "assets/SET_MODE",
    }),
    ...mapActions({
      fetchAll: "customerNotification/fetchAll",
    }),
    updateUploadProgress(progressEvent) {
      this.fileUploadProgress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },
    uploadFileHandle(e) {
      pushEvent("uploadFile");

      this.source = axios.CancelToken.source();

      if (e.length > 1) {
        this.multipleFileUpload(e);
      } else {
        this.singleFileUpload(e);
      }
    },
    singleFileUpload(file) {
      if (file && file[0] && maxMbSize(file[0], this.maxSizeInMb)) {
        this.uploadingImage = true;
        this.uploadFileName = file[0].name;
        const body = new FormData();
        body.append("image", file[0]);
        body.append("name", this.generateFileName(file[0].name));
        api
          .uploadUserImage(body, this.source.token, this.updateUploadProgress)
          .then(() => {
            this.setReload();
          })
          .catch((error) => {
            if (error && error.constructor.name !== "Cancel") {
              errorNotification(null, error);
            }
          })
          .finally(() => {
            this.uploadingImage = false;
            this.fileUploadProgress = 0;
          });
      } else {
        errorNotification("upload_photo_error_message", null, false);
      }
    },
    multipleFileUpload(files) {
      const requests = [];
      files.forEach((file) => {
        if (file && maxMbSize(file, this.maxSizeInMb)) {
          const body = new FormData();
          body.append("image", file);
          body.append("name", this.generateFileName(file.name));
          requests.push(
            api.uploadUserImage(
              body,
              this.source.token,
              this.updateUploadProgress
            )
          );
        } else {
          errorNotification("upload_photos_error_message", null, false);
        }
      });

      this.uploadingImage = true;
      Promise.all(requests)
        .catch((error) => {
          if (error && error.constructor.name !== "Cancel") {
            errorNotification(null, error);
          }
        })
        .finally(() => {
          this.setReload();
          this.uploadingImage = false;
          this.fileUploadProgress = 0;
        });
    },
    generateFileName(name) {
      if (name && name.length > 30) return name.slice(0, 30);
      return name;
    },
  },
};
