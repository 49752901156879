<template>
  <v-container fluid>
    <v-row>
      <v-col cols="10" md="6">
        <v-text-field
          v-model="searchText"
          :hint="$trans('search_for_photos_hint')"
          :label="$trans('search_for_photos')"
          :placeholder="$trans('for_example_unsplash')"
          append-icon="$magnify"
          outlined
          persistent-hint
          :loading="isLoading"
          @input="searchDebounce"
        />
      </v-col>
      <v-col class="d-flex align-start mt-4" cols="2">
        <a
          href="https://unsplash.com/?utm_source=Calendesk&utm_medium=referral"
          target="_blank"
        >
          <small>{{ $trans("photos_by_unsplash") }}</small>
        </a>
      </v-col>
    </v-row>
    <v-row v-if="items.length > 0">
      <v-col>
        <v-pagination
          v-model="currentPage"
          :disabled="isLoading"
          :length="lastPaginationPage"
          circle
          color="primary"
          total-visible="7"
          @input="search(true)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(item, index) in items"
        :key="index"
        class="pointer"
        cols="6"
        md="2"
        sm="4"
        xl="1"
      >
        <v-hover>
          <template #default="{ hover }">
            <v-card>
              <v-img :src="item.urls.thumb" aspect-ratio="1">
                <template #placeholder>
                  <v-skeleton-loader
                    class="mx-auto"
                    max-width="300"
                    type="image"
                  />
                </template>
              </v-img>
              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  class="text-center"
                  @click.native="openPreviewAsset(item.urls.regular)"
                >
                  <v-row>
                    <v-col>
                      <a
                        :href="
                          item.user.links.html +
                          '?utm_source=Calendesk&utm_medium=referral'
                        "
                        class="white--text text-decoration-none"
                        target="_blank"
                      >
                        <small>
                          {{
                            $trans("photo_by_on_unsplash", {
                              name: item.user.name,
                            })
                          }}
                        </small>
                      </a>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                        class="mr-2"
                        icon
                        x-large
                        @click.stop="openPreviewAsset(item.urls.regular)"
                      >
                        <v-icon x-large> $zoom_plus</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="!isDownloaded(item.id)"
                        class="ml-2"
                        icon
                        x-large
                        @click.stop="download(item.id)"
                      >
                        <v-icon x-large> $download</v-icon>
                      </v-btn>
                      <v-icon v-else color="success" x-large>
                        $check-circle
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
    <v-row v-if="items.length > 0">
      <v-col>
        <v-pagination
          v-model="currentPage"
          :disabled="isLoading"
          :length="lastPaginationPage"
          circle
          color="primary"
          total-visible="7"
          @input="search(true)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { debounce } from "debounce";
import { mapActions, mapGetters, mapMutations } from "vuex";
import api from "@/lib/calendesk-js-library/api/api";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "UnsplashSearchPhotos",
  data() {
    return {
      isLoading: true,
      items: [],
      currentPage: 1,
      lastPaginationPage: 1,
      searchText: null,
      downloaded: [],
    };
  },
  computed: {
    ...mapGetters({
      initialTextSearch: "assets/getInitialTextSearch",
    }),
  },
  created() {
    this.searchText = this.initialTextSearch;
    this.search();
  },
  methods: {
    ...mapActions({
      openPreviewAsset: "assets/openPreviewAsset",
    }),
    ...mapMutations({
      setReload: "assets/SET_RELOAD",
    }),
    isDownloaded(id) {
      return this.downloaded.includes(id);
    },
    search(keepPage) {
      if (!this.searchText) {
        this.items = [];
        return;
      }

      pushEvent("searchForUnsplashPhoto");

      if (!keepPage) {
        this.currentPage = 1;
      }

      this.isLoading = true;
      this.$emit("loading", true);
      api
        .searchForUnsplashPhotos({
          query: this.searchText,
          page: this.currentPage,
        })
        .then(({ data }) => {
          this.items = data.data;
          this.lastPaginationPage = data.last_page;
        })
        .catch((error) => {
          errorNotification(null, error);
        })
        .finally(() => {
          this.isLoading = false;
          this.$emit("loading", false);
        });
    },
    searchDebounce: debounce(function () {
      this.search();
    }, 600),
    download(id) {
      pushEvent("downloadUnsplashPhoto");

      this.isLoading = true;
      this.$emit("loading", true);
      this.downloaded.push(id);

      api
        .selectUnsplashPhoto(id)
        .then(({ data }) => {
          successNotification("unsplash_photo_added_to_gallery");
          this.setReload();
        })
        .catch((error) => {
          this.downloaded.splice(this.downloaded.indexOf(id), 1);
          errorNotification(null, error);
        })
        .finally(() => {
          this.$emit("loading", false);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
